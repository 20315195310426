export const aboutSubPagesTranslationKeys = {
  label: 'label',
  photoDescriptions: 'photo_descriptions',
};

export const productDetailInfoKeys = {
  object: 'object',
  name: 'name',
  material: 'material',
  description: 'description',
  photo: 'photo',
};
